<template>
    <div class="userlist_content">
        <div class="userlist_top fixed">
            <van-search v-model="searchValue" placeholder="搜索客户名称" @search="onSearch" />

            <van-dropdown-menu active-color="#1989fa">
                <van-dropdown-item
                    v-model="valueState"
                    :options="option"
                    @opened="openItem(0)"
                    :disabled="disabledSel"
                    @close="closeItem"
                />
                <van-dropdown-item
                    :title="tabTitle"
                    ref="item"
                    @opened="openItem(1)"
                    :disabled="disabledSel"
                    @close="closeItem"
                >
                    <!-- 标签选择 -->
                    <div class="content_list">
                        <div class="labels_list">
                            <inLabels
                                :labelsList="labels_list"
                                @clickNum="getClickNum"
                                @clickList="handleAddTag"
                                :isReset="isReset"
                                :isConfirm="isConfirm"
                            />
                        </div>
                    </div>
                </van-dropdown-item>
            </van-dropdown-menu>
            <div class="outer" v-show="isShowButton">
                <div class="button_list">
                    <button class="reset button" @click="reset">重置</button>
                    <button class="confirm" @click="confirm">确认({{ curClickNum }})</button>
                </div>
            </div>

            <div class="center_tip" v-if="isShowCustomerTotal">共有{{ userNumber }}位客户</div>
        </div>
        <div class="userlist_center" v-if="!isShowEmpty">
            <van-pull-refresh v-model="refreshing" @refresh="onDownRefresh">
                <van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad">
                    <div class="center_list" ref="user_list">
                        <div
                            v-for="(item, index) in user_list"
                            :key="index"
                            class="center_list_item"
                            :ref="`user_item${index}`"
                        >
                            <div class="center_list_liContent">
                                <div class="center_list__box">
                                    <div class="center_list__avatar">
                                        <img
                                            class="un_select"
                                            v-default-avatar
                                            :src="item.avatar || item.xiaoe_user_info.wx_avatar"
                                            alt="头像"
                                        />
                                    </div>
                                    <div class="center_list__info">
                                        <div class="info__name">{{ item.name }}</div>
                                        <div class="info__date">备注：{{ item.follow_user_remark }}</div>
                                        <div class="info__date">
                                            添加员工：
                                            <span
                                                v-for="(staff_item, staff_index) in item.staff_list"
                                                :key="`staff${staff_index}`"
                                            >
                                                {{ allUserMap[staff_item] || '待分配' }}
                                            </span>
                                        </div>
                                        <div class="info__date">
                                            {{ item.created_at }}
                                        </div>
                                    </div>
                                </div>
                                <button class="contact-btn" v-show="!isInMiniprogram" @click="callTa(item)">
                                    联系Ta
                                </button>
                            </div>
                            <div v-if="item.corp_tag.length != 0" class="tip_group" ref="user_tags">
                                <span
                                    v-for="(group_item, group_index) in item.corp_tag"
                                    :key="group_index"
                                    class="tips"
                                >
                                    {{ group_item.name }}
                                </span>
                            </div>
                            <div class="jump" @click="openTags($event, item, index)" v-if="item.corp_tag.length">
                                <span>展开全部标签(</span>
                                {{ item.corp_tag.length }}
                                <span>)</span>
                                <img class="jump_img" src="@/assets/images/jump.png" />
                            </div>
                        </div>
                    </div>
                </van-list>
            </van-pull-refresh>
        </div>
        <empty-page :msg="emptyMsg" v-if="isShowEmpty"></empty-page>
    </div>
</template>

<script>
import { AVATAR_DEFAULT_IMG } from '@/constant';
import InLabels from './Individual-labels.vue';
import emptyPage from '@/components/emptyPage.vue';
import Http from '@xiaoe/venus/dist/net/request';
import { mapState } from 'vuex';

export default {
    name: 'userList',
    components: {
        InLabels,
        emptyPage
    },
    watch: {
        valueState(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.user_list = [];
                this.page = 1;
                (this.finished = false), //上拉加载完毕
                    this.setLoading();
                this.getData();
            }
        }
    },
    computed: {
        ...mapState(['isInMiniprogram'])
    },
    data() {
        return {
            AVATAR_DEFAULT_IMG,
            valueState: -1,
            userNumber: 0,
            switch1: false,
            switch2: false,
            option: [
                { text: '全部状态', value: -1 },
                { text: '未流失', value: 1 },
                { text: '已流失', value: 0 }
            ],
            searchValue: '',
            user_list: [],
            labels_list: [],
            curClickNum: 0,
            page: 0,
            isDown: false,
            loading: false, //上拉加载
            finished: false, //上拉加载完毕
            refreshing: false, //下拉刷新
            params: {},
            tagsNum: 0,
            tabTitle: '全部企业标签',
            isOpenTags: false,
            isReset: false,
            isConfirm: false,
            isShowButton: false,
            curClickList: [],
            isShowEmpty: false,
            emptyMsg: '暂无客户',
            disabledSel: true,
            curSreenPx: 0,
            curLangTagsMap: {},
            isShowCustomerTotal: true,
            allUserMap: {},
            lastTime: 0 //默认上一次点击时间为0
        };
    },

    created() {
        let curScreenWidth = document.body.clientWidth;
        this.curSreenPx = (curScreenWidth / 375) * 50;
    },

    mounted() {
        this.getLabelData();
        //获取标签
        if (this.$store.state.staff_list) {
            this.allUserMap = this.$store.state.staff_list;
        } else {
            this.allUserMap = {};
        }
        this.customerTotal();
    },

    updated() {
        if (this.$refs['user_tags']) {
            let el = this.$refs['user_tags'];
            let standardSize = this.curSreenPx * 1.12;
            el.forEach((item, index) => {
                if (item.scrollHeight > standardSize) {
                    document.getElementsByClassName('jump')[index].style.display = 'block';
                    item.style.height = '0.56rem';
                    item.style.height = '1rem';
                    item.style.overflow = 'hidden';
                } else {
                    document.getElementsByClassName('jump')[index].style.display = 'none';
                }
            });
        }
    },
    methods: {
        onLoad() {
            console.log('触发了');
            if (this.refreshing) {
                this.user_list = [];
                this.refreshing = false;
            }
            this.page++;
            this.getData();
        },

        // 下拉刷新
        onDownRefresh() {
            this.finished = false;
            this.loading = true;
            this.page = 1;
            if (this.refreshing) {
                this.user_list = [];
                this.refreshing = false;
            }
            this.refreshData();
        },

        async refreshData() {
            this.params = {
                customer_name: `${this.searchValue}` || '',
                page: this.page,
                page_size: 10,
                channel_remark: '',
                customer_churn_state: this.valueState,
                corp_tag: this.curClickList.length ? this.curClickList : [],
                follow_status: -1,
                add_customer_time_start: '',
                add_customer_time_end: ''
            };
            await Http.post('/forward_admin/wework/custom/custom_search', this.params)
                .then(({ data }) => {
                    this.$toast.clear();
                    let resCode_List = data.list;
                    if (resCode_List.length > 0) {
                        this.user_list = resCode_List;
                        this.loading = false;
                    } else {
                        this.finished = true; //完成加载
                    }
                    this.disabledSel = false;
                    this.isShowEmpty = !this.user_list.length ? true : false;
                })
                .catch((err) => {
                    this.$toast(err.msg);
                });
        },
        async customerTotal() {
            await Http.post(
                '/forward_admin/wework/custom/custom_search_total',
                {}
            )
                .then(({ data }) => {
                    const { total } = data;
                    this.userNumber = total;
                })
                .catch((err) => {
                    this.$toast(err.msg);
                });
        },
        onSearch() {
            this.page = 1;
            this.finished = false;
            this.loading = true;
            this.isDown = true;
            let now = new Date().valueOf();
            if (this.lastTime == 0) {
                this.user_list = [];
                this.refreshData();
                this.lastTime = now;
            } else {
                if (now - this.lastTime > 2000) {
                    this.lastTime = now;
                    this.user_list = [];
                    this.refreshData();
                } else {
                    console.log('防止事件多次触发');
                }
            }
        },

        getData() {
            this.params = {
                customer_name: `${this.searchValue}` || '',
                page: this.page,
                page_size: 10,
                channel_remark: '',
                customer_churn_state: this.valueState,
                corp_tag: this.curClickList.length ? this.curClickList : [],
                follow_status: -1,
                add_customer_time_start: '',
                add_customer_time_end: ''
            };
            this.getDataAjax();
        },

        async getDataAjax() {
            this.isOpenTags = false;

            await Http.post('/forward_admin/wework/custom/custom_search', this.params)
                .then(({ data }) => {
                    this.$toast.clear();
                    let resCodeList = data.list;
                    if (resCodeList.length > 0) {
                        this.user_list = this.user_list.concat(resCodeList);
                        this.loading = false;
                    } else {
                        this.finished = true; //完成加载
                    }
                    this.disabledSel = false;
                    this.isShowEmpty = this.finished && !this.user_list.length ? true : false;
                })
                .catch((err) => {
                    this.$toast(err.msg);
                });
        },

        async getLabelData() {
            const headers = { 'X-XE-SOURCE': 'wework_self_build' };
            const url = '/forward_admin/wework/custom/tag_list';
            const params = { tag_type: 1 };
            await Http.request({
                url,
                method: 'get',
                params,
                headers
            })
                .then((res) => {
                    this.labels_list = res.data;
                })
                .catch((err) => {
                    this.$toast(err.msg);
                });
        },

        handleAddTag(list) {
            this.isConfirm = false;
            this.setLoading();
            this.user_list = [];
            this.tagsNum = list.length;
            this.tabTitle = list.length ? `已选标签(${this.tagsNum})` : '全部企业标签';
            this.onConfirm();
            console.log(list);
            this.curClickList = list;
            this.page = 1;
            this.finished = false;
            this.loading = true;
            if (this.refreshing) {
                this.user_list = [];
                this.refreshing = false;
            }
            this.params = {
                customer_name: `${this.searchValue}` || '',
                page: this.page,
                page_size: 10,
                channel_remark: '',
                customer_churn_state: this.valueState,
                corp_tag: this.curClickList,
                follow_status: -1,
                add_customer_time_start: '',
                add_customer_time_end: ''
            };
            this.refreshData();
        },
        callTa(item) {
            console.log(wx.invoke, item.external_userid, 'item.external_userid');
            console.log(item, '联系人');
            wx.invoke(
                'openUserProfile',
                {
                    type: 2, //1表示该userid是企业成员，2表示该userid是外部联系人
                    userid: item.external_userid //可以是企业成员，也可以是外部联系人
                },
                function (res) {
                    console.log(res, 'openUserProfile结果');
                    if (res.err_msg != 'openUserProfile:ok') {
                        this.$toast(res.err_msg);
                    }
                }
            );
        },
        openTags(e, item, index) {
            if (e) {
                document.getElementsByClassName('center_list_item')[index].children[1].style.height = 'auto';
                document.getElementsByClassName('center_list_item')[index].children[1].style.overflow = 'auto';
                document.getElementsByClassName('center_list_item')[index].children[2].style.display = 'none';
                this.curLangTagsMap[item.external_id] = true;
            }
            this.isOpenTags = false;
        },
        openItem(type) {
            this.isShowCustomerTotal = false;
            if (type) {
                let dropDown0 = document.getElementsByClassName('van-dropdown-item__content')[0] || '';
                let dropDown1 = document.getElementsByClassName('van-dropdown-item__content')[1] || '';
                if (this.labels_list.length == 0) {
                    if (dropDown0.style) {
                        dropDown0.style.height = '5rem';
                    }
                    if (dropDown1.style) {
                        dropDown1.style.height = '5rem';
                    }
                    this.isShowButton = false;
                } else {
                    if (dropDown0.style) {
                        dropDown0.style.height = '5.52rem';
                    }
                    if (dropDown1.style) {
                        dropDown1.style.height = '5.52rem';
                    }
                    this.isShowButton = true;
                }
            } else {
                document.getElementsByClassName('van-dropdown-item__content')[0].style.height = '';
            }
        },
        closeItem() {
            this.isShowButton = false;
            this.isShowCustomerTotal = true;
            document.getElementsByClassName('van-dropdown-item__content')[0].style.height = '';
        },
        onConfirm() {
            this.$refs.item.toggle();
        },
        getClickNum(val) {
            this.curClickNum = val;
            this.isReset = false;
        },
        reset() {
            this.isReset = true;
        },
        confirm() {
            this.isConfirm = true;
        },
        setLoading() {
            this.$toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.userlist_content {
    overflow: auto;
    height: 100vh;
    .userlist_top {
        width: 100%;
        position: sticky;
        top: 0;
        z-index: 9999;
        .center_tip {
            padding: 0.24rem 0.32rem;
            background-color: #f5f5f5;
            font-size: 0.24rem;
            font-weight: 400;
            color: #999999;
            line-height: 0.32rem;
        }
        .content_list {
            display: flex;
            flex-direction: column;
            .labels_list {
                flex: 1;
            }
        }
    }
    .userlist_center {
        .center_list {
            padding: 0 0.32rem;
        }
    }
    .userlist_bottom {
        height: 2rem;
        width: 100%;
        text-align: center;
        line-height: 2rem;
        color: rgb(202, 202, 203);
        font-size: 14px;
    }
    .center_list_item {
        display: flex;
        flex-direction: column;
        padding: 0.24rem 0.32rem;
        margin-top: 0.16rem;
        display: flex;
        background: #ffffff;
        border-radius: 0.16rem;
        .center_list_liContent {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .center_list__box {
            display: flex;
            overflow: hidden;
        }
        .center_list__avatar {
            width: 1.32rem;
            height: 1.32rem;
            background: #ffffff;
            border-radius: 0.16rem;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
                width: 1.32rem;
                height: 1.32rem;
                border-radius: 50%;
            }
        }
        .center_list__info {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-left: 0.16rem;
            margin-right: 0.08rem;
            overflow: hidden;
        }
        .info__name,
        .info__date {
            word-break: keep-all;
            white-space: nowrap;
            max-width: 3.3rem;
            overflow-x: hidden;
            text-overflow: ellipsis;
        }
        .info__name {
            font-size: 0.28rem;
            font-weight: 400;
            color: #333333;
            line-height: 0.4rem;
            text-overflow: -o-ellipsis-lastline;
        }
        .info__date {
            font-size: 0.24rem;
            font-weight: 400;
            color: #999999;
            line-height: 0.36rem;
            margin-top: 0.04rem;
        }
        .contact-btn {
            // width: 1.12rem;
            height: 0.48rem;
            border-radius: 2rem;
            border: 1px solid #1472ff;
            background: transparent;
            outline: none;
            font-size: 0.24rem;
            font-weight: 400;
            color: #1472ff;
            line-height: 0.24rem;
            word-break: keep-all;
            white-space: nowrap;
        }
        .tip_group_overflow {
            // height: 0.56rem;
            // overflow: hidden;
        }
        .tip_group_overflow_open {
            max-height: auto;
        }
        .jump {
            text-align: center;
            font-size: 0.24rem;
            font-weight: 400;
            color: #999999;
            margin-top: 0.32rem;
            display: none;
        }
        .jump_img {
            width: 0.32rem;
            height: 0.32rem;
            background: #ffffff;
            border-radius: 0.16rem;
            vertical-align: middle;
        }
        .tip_group {
            margin-top: 0.16rem;
            .tips {
                display: inline-block;
                font-size: 12px;
                line-height: 0.32rem;
                height: 0.32rem;
                background: #e7edfb;
                border-radius: 0.26rem;
                font-size: 0.2rem;
                font-weight: 400;
                color: #1472ff;
                padding: 0.06rem 0.08rem;
                margin: 0.04rem 0.06rem 0.04rem 0;
            }
        }
    }
    .outer {
        position: relative;
        height: 8rem;
    }
    .button_list {
        // position: fixed;
        // top: 9.38rem;
        position: absolute;
        // bottom: 0;
        bottom: 1.2rem;
        background-color: #fff;
        width: 100%;
        padding: 0.24rem 0;
        display: flex;
        justify-content: space-around;
        align-items: center;
        z-index: 9999;
        border-radius: 0rem 0rem 0.24rem 0.24rem;
        .button {
            width: 3.28rem;
            height: 0.8rem;
            background: #ffffff;
            border-radius: 0.4rem;
            border: 1px solid #cccccc;
            outline: none;
        }
        .confirm {
            width: 3.28rem;
            height: 0.8rem;
            background: #1472ff;
            border-radius: 0.4rem;
            font-size: 0.32rem;
            font-weight: 500;
            color: #ffffff;
            line-height: 0.48rem;
            border: none;
            outline: none;
            // border: 1px solid #ffffff;
        }
    }
    .van-dropdown-item__content {
        height: 80%;
    }
}
</style>
<style lang="scss">
$varHeight: var(--height-parimary);
.userlist_content {
    .van-dropdown-item__content {
        max-height: 5.52rem;
        margin: 0px auto;
        z-index: 2006;
        right: 0px;
        max-width: 480px;
        height: $varHeight;
    }
    .van-dropdown-menu__bar {
        height: 0.88rem;
        box-shadow: none;
    }
    .van-overlay {
        bottom: 0px;
        margin: auto;
        right: 0px;
        max-width: 480px;
    }
}
.userlist_center {
    .van-list {
        background: #f5f6f9;
    }
}
</style>
