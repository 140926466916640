<template>
  <div class="label_content">
    <div class="label_contentList" v-if="!isShowEmpty">
      <div
        class="label_group"
        v-for="(item, index) in labels_list"
        :key="index"
      >
        <li class="label_group_li">
          <div class="label_title">{{ item.tag_group_name }}</div>
          <div class="label_groupContent">
            <span
              v-for="(label_item, label_index) in item.tags"
              :key="label_index"
              @click="labelClick($event, label_item.tag_id, label_index)"
              :class="
                clickMap[label_item.tag_id]
                  ? 'label_item label_itemClick'
                  : 'label_item'
              "
            >
              {{ label_item.tag_name }}
            </span>
          </div>
        </li>
      </div>
    </div>
    <empty-page :msg="emptyMsg" v-if="isShowEmpty"></empty-page>
  </div>
</template>

<script>
import emptyPage from "@/components/emptyPage.vue";
export default {
  props: {
    labelsList: {
      type: Array,
      default: function () {
        return [];
      },
    },
    isReset: {
      type: Boolean,
    },
    isConfirm: {
      type: Boolean,
    },
  },
  watch: {
    isReset(val) {
      if (val) {
        this.reset();
      }
    },
    isConfirm(newVal) {
      if (newVal) {
        this.confirm();
      }
    },
  },
  name: "individualLabels",
  components: {
    emptyPage,
  },
  data() {
    return {
      labels_list: [],
      isClick: false,
      clickList: [],
      clickMap: {},
      clickListLength: 0,
      isShowEmpty: false,
      emptyMsg: "暂无新建企业标签",
    };
  },
  created() {
    this.labels_list = this.labelsList;
    if (this.labelsList.length == 0) this.isShowEmpty = true;
  },
  mounted() {},
  methods: {
    labelClick(e, item) {
      if (this.clickMap[item]) {
        this.$set(this.clickMap, item, false);
        var index = this.clickList.indexOf(item);
        if (index > -1) {
          this.clickList.splice(index, 1);
        }
      } else {
        this.$set(this.clickMap, item, 15);
        this.clickList.push(item);
      }
      this.clickListLength = this.clickList.length;
      this.$emit("clickNum", this.clickList.length);
    },
    reset() {
      this.clickList = [];
      this.confirm();
      this.clickMap = {};
      this.labels_list = this.labelsList;
      this.clickListLength = 0;
      this.$emit("clickNum", 0);
    },
    confirm() {
      this.$emit("clickList", this.clickList);
    },
  },
};
</script>
<style lang="scss" scoped>
.label_content {
  width: 100%;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  .label_title {
    font-size: 0.32rem;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    width: 100%;
    float: left;
    margin-top: 0.32rem;
    margin-bottom: 0.28rem;
  }
  .label_contentList {
    flex: 1;
    z-index: 9999;
    position: relative;
    .label_group {
      .label_group_li {
        list-style: none;
        padding: 0.32rem 0.56rem;
      }
      .label_groupContent {
        float: left;
        padding-right: 1rem;
        .label_item {
          float: left;
          margin: 0.15rem 0.12rem;
          padding: 0.06rem 0.16rem;
          background: #f5f5f5;
          border-radius: 0.26rem;
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          line-height: 0.36rem;
          border: 1px solid #f5f5f5;
        }
        .label_itemClick {
          margin: 0.15rem 0.12rem;
          padding: 0.06rem 0.16rem;
          background: #e7edfb;
          border-radius: 0.26rem;
          border: 1px solid #1472ff;
          font-size: 0.24rem;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #105cfb;
          line-height: 0.36rem;
        }
      }
    }
  }
}
</style>
